
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(Roboto-Medium.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(Roboto-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(Roboto-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: url(Roboto-Italic.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-display: swap;
    font-weight: 700;
    src: url(Roboto-BoldItalic.ttf) format('truetype');
  }