
.view-border-bottom {
    border-bottom: 1px solid $lcars-purple;
}

.view-field-value {
    font-size: 1.2rem;
}

.view-field-label {
    color: $lcars-purple;
    font-family: var(--lcars-header-font);
    font-size: 1.6rem;
    text-transform: uppercase;
}

.pill-left {
    display: inline-block;
    color: white;
    padding: 5px 10px;
    height: 24px;
    background-color: $lcars-dull-pink;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    line-height: 1;
    text-align: right;
    text-transform: uppercase;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

.pill-left-sm {
    //padding-top: 2px;
    max-width: 50%;
}

.pill-left .delta::after {
    color: white;
}


.pill-right {
    color: white;
    display: inline-block;
    padding: 5px 10px;
    height: 24px;
    margin-left: 0.25rem;
    background-color: $lcars-dull-pink;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    line-height: 1;
    width: calc(100% - 120px - 0.25rem);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

.pill-right-sm {
    min-width: calc(50% - 0.25rem);
    text-align: center;
}

.empty-pill {
    margin-right: 0.5rem;
    border: 2px solid $lcars-purple;
    width: calc(20% - 0.5rem);
    height: 24px;
    border-radius: 12px;
}
.empty-pill.compact {
    width: calc(20% - 0.25rem);
    margin-right: 0.25rem;
    height: 20px;
}
.empty-pill.solid {
    background-color: $lcars-purple;
}