@font-face {
    font-family: 'trekarrows';
    src: url('../fonts/Trek_Arrowheads.ttf') format('truetype');
    font-style: normal;
}

.delta {
    color: inherit;
    letter-spacing: -1px;
    font-size: 0px;
}
.delta::after {
    font-size: 1rem;
    content: "A";
    color: #F9AC76;
    font-family: 'trekarrows';
}

table.selection-list .delta::after {
    font-size: 12px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    table.selection-list .delta::after {
        font-size: 10px;
    }
}