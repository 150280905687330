@font-face {
    font-family: 'lcars';
    src: url('../fonts/lcars_font.TTF') format('truetype');
    font-weight: lighter;
    font-style: normal;
}
@import "../fonts/bebas-neue";
@import "../fonts/roboto";

$lcars-salmon: #CC6666;
$lcars-purple: #8C6996;
$lcars-brighter-purple: #9E8ABD; //#9179B7; - original colour does not provide sufficient contast
$lcars-orange: #F89B24;
$lcars-pink: #da70d6;
$lcars-dull-purple: #B5B3D8; // used for buttons (or is it?)
$lcars-light-orange: #FFCC66;
$lcars-dull-orange: #F9AC76;
$lcars-burnt-orange: #DA7E07; // used for links
$lcars-light-pink: #DE84B7;
$lcars-dull-pink: #c37ba4;
$lcars-very-light-pink: #f7bbdd;

$breadcrumb-divider-color: #999999;
$breadcrumb-active-color: #999999;

$primary: $lcars-purple;
$danger: $lcars-salmon;
$info: #F9AC76;
$success: #9999ff;
$warning: $lcars-light-orange;

$input-border-color: $lcars-purple;
$input-border-width: 2px;
$input-font-size: inherit;
$enable-validation-icons: false;

$modal-content-bg: $lcars-brighter-purple;
$modal-content-color: white;
$modal-content-border-radius: 1.25rem;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import "~bootstrap/scss/maps";
@import '~bootstrap/scss/root';

$bootstrap-icons-font-src: url("~bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"), url("~bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");

@import '~bootstrap-icons/font/bootstrap-icons.scss';

$breadcrumb-bg: #130e1a;
$breadcrumb-border-radius: 1rem;
$breadcrumb-padding-x: 1.5rem;

$btn-border-radius:           1.25rem;
$btn-border-radius-lg:        1.25rem;
$btn-border-radius-sm:        1.25rem;
$btn-border-width:            0px;

$btn-font-family:             lcars;


@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap-grid';

@import './common.scss';
@import './attributes.scss';
@import './characterSheet.scss';
@import './components.scss';
@import './dialog.scss';
@import './skills.scss';
@import './sourceselection.scss';
@import './specialChars.scss';
@import './view.scss';
@import './map.scss';