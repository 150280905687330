﻿:root {
    --lcars-header-font: lcars;
    --lcars-text-font: Roboto;
}

@import "lang-ru";

@mixin scroll {
  &::-webkit-scrollbar-thumb {
    background-color: #F89B24;
    border-radius: 50px;
  }

  &::-webkit-scrollbar {
    background-color: #F89B2480;
    border-radius: 50px;
    padding: 0;
    margin: 0;
  }

  scrollbar-color: #F89B24 #7d4e15;
}

.d-md-table-cell {
    display: none !important;
}

@media screen and (min-width: 768px) {
    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-block {
        display: block !important;
    }
}

p {
    color: white;
    font-size: large;
    font-weight: normal;
}

.markdown-sm {
    p {
        font-size: small !important;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
}


.container ul li {
    color: white;
    font-size: large;
    font-weight: normal;
}

#mainBody {
	width: calc(100% - 20px);
	height: calc(100% - 20px);
    margin: 10px;
}

#app {
    margin: 20px 0 30px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll;
}

h3, h4, h5 {
    line-height: 1.2;
    margin-top: 0rem;
    margin-bottom: .5rem;
}

h3 {
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

.arrow-up {
	width: 0;
	height: 0;
	border-right: 10px solid transparent;
	border-bottom: 10px solid white;
	border-left: 10px solid transparent;
    margin: auto;
    margin-top: 9px;

		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		-o-transform: rotate(0);
	transform: rotate(0);

		-webkit-transition: all .2s ease;
		-moz-transition: all .2s ease;
		-o-transition: all .2s ease;
	transition: all .2s ease;
}
/* ---- Stats Block ----- */
.stat-pill {
    display: flex;
    align-items: stretch;

    .stat-name {
        padding-top: 2px;
        padding-bottom: 2px;
        font-family: var(--lcars-header-font);
        font-size: 16px;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .stat-value {
        flex-grow: 0;
        flex-shrink: 0;
        line-height: 1;
    }
}

@media screen and (min-width: 0px) and (max-width: 975px) {
    .stat-pill {
        .stat-name {
            font-size: 14px;
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .stat-pill {
        .stat-name {
            font-size: 13px;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    .container p, .container ul li {
        font-size: 14px;
    }
}
.stat-name {
    display: inline-block;
    padding: 4px;
    width: 108px;
    border-radius: 10px 0 0 10px;
    text-align: right;
    text-transform: uppercase;
    height: 22px;
    margin: 0 0.25rem 0 0;
    line-height: normal;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stat-name.stat-name-sm {
    padding: 3px;
    width: 80px;
    border-radius: 10px 0 0 10px;
    height: 18px;
    font-size: 10px;
}

.stat-entry-name {
    color: white;
    box-sizing: border-box;
    display: inline-block;
    padding: 4px;
    width: 100px;
    border-radius: 15px 0 0 15px;
    text-align: right;
    text-transform: uppercase;
    height: 30px;
    margin: 0 0.25rem 0 0;
    font-family: var(--lcars-header-font);
    font-size: large;
    background-color: $lcars-purple;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stat-name.purple, .stat-value.purple {
    background-color: $lcars-purple;
    color: white;
}
.stat-name.orange, .stat-value.orange {
    background-color: #F89B24;
    color: white;
}
.stat-name.red, .stat-value.red {
    background-color: #CC6666;
    color: white;
}
.stat-name.pink, .stat-value.pink {
    background-color: $lcars-dull-pink;
    color: white;
}

.stat-value {
    display: inline-block;
    padding: 4px;
    width: 26px;
    border-radius: 0 10px 10px 0;
    text-align: center;
    text-transform: uppercase;
    height: 22px;
    margin: 0;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stat-value.stat-value-sm {
    padding: 3px;
    width: 22px;
    height: 18px;
    font-size: 10px;
}
.stat-entry-value {
    box-sizing: border-box;
    display: inline-block;
    background-color: black;
    color: white;
    padding: 4px;
    padding-right: 10px;
    width: 105px;
    height: 30px;
    text-align: center;
    border: 2px solid #906F9A;
    border-radius: 0 15px 15px 0;
}

.stats-block, .panel div.stats-block {
    padding: 0;
}

.stats-row, .stat, .panel div.stat, .panel div.stats-row {
    padding: 0 15px 3px 0;
}

.stat {
    display: flex;
    align-items: center;
}

.stats-row {
    display: flex;
}

@media screen and (min-width: 0px) and (max-width: 975px) {

    .stats-row {
        display: block;
    }
    .stat-value {
        font-size: 10px;
        height: 19px;
        width: 28px;
    }
    .stat-name {
        font-size: 10px;
        height: 19px;
        width: 88px;
    }
}

/* ---- END: Stats Block ----- */

.w-100 {
    width: 100% !important;
}

.arrow-down {
	transform: rotate(-180deg);
}

b {
    text-transform: uppercase;
}

.skill-name b {
    text-transform: none;
}

body {
    width: 100%;
    height: 100%;
    zoom: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    //font-family: Helvetica;
    font-family: var(--lcars-text-font);
    font-size: small;
    padding: 0;
    margin: 0;
    background: black;
}

.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

.dl-horizontal dt {
    white-space: normal;
}

html {
    height: 100%;
    width: 100%;
}

input {
    width: 300px;
    color: white !important;
    background-color: transparent;
    border: 2px solid #DE84B7;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 6px;
}

input[type=search] {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

input[type=search]::-webkit-search-cancel-button{

    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    margin-left: .4em;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    cursor: pointer;
}

textarea {
    width: 300px;
    color: white !important;
    background-color: transparent;
    border: 2px solid #DE84B7;
    border-radius: 4px;
    padding: 6px;
}

select {
    display: block;
    max-width: 100%;
    width: 280px;
    color: white;
    background-color: $lcars-purple;
    padding: 5px;
    border-radius: 8px;
    border: 0;
}

input[type=radio],
input[type=checkbox] {
    width: 23px;
    height: 23px;
    border: 2px solid #906F9A;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=radio]:checked,
input[type=checkbox]:checked {
    background-color: #906F9A;
    border: 2px solid #906F9A;
}

input[type="checkbox"]:disabled {
    background-color: black;
    border: 2px solid grey;
}

.modal-content input[type=radio],
.modal-content input[type=checkbox] {
    border: 2px solid #B5B3D8;
}

.modal-content input[type=radio]:checked,
.modal-content input[type=checkbox]:checked {
    background-color: #B5B3D8;
    border: 2px solid #B5B3D8;
}


input[type=radio]:focus,
input[type=checkbox]:focus {
    outline: 0;
}

a,
a:hover {
    color: $lcars-burnt-orange;
}

.breadcrumb-item a,
.breadcrumb-item a:hover {
    color: #9999ff;
}

.content-container {
    width: 100%;
    max-width: 800px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}

.content {
    transition: margin-left linear .25s; /* should match .content-container-fullscreen transition */
}

.content-nudged {
    /*margin-left: 320px;*/ /* Should match width of sheet-container */
}

.desc-text {
    padding: 4px;
    text-align: justify;
}

.header-small {
    color: black;
    background: #F89B24;
    padding: 10px;
    margin-bottom: 10px;
    font-family: var(--lcars-header-font);
    font-size: x-large;
    text-transform: uppercase;
    width: 25%;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.header-text {
    color: black;
    background-color: #FFCC66;
    font-family: var(--lcars-header-font);
    font-size: 40px;
    text-transform: uppercase;
    border-radius: 20px;
    padding-left: 30px;
    margin-bottom: 10px;
    line-height: 38px;
    font-weight: normal;
}

h2.header-text {
    background-color: $lcars-orange;
    font-size: 30px;
    text-transform: uppercase;
    border-radius: 15px;
    padding-left: 30px;
    line-height: 30px;
}

.header-text > div {
    background: black;
    color: white;
    display: inline-block;
    padding: 0px 10px;
}

.header-text-small {

}

.lcar-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.lcar-header {
	flex: 1;
	max-height: 60px;
	display: flex;
	flex-direction: row;
}
	.lcar-header > div {
		background: #F89B24;
		flex: 1;
		margin-left: 10px;
	}

	.lcar-header-start {
		margin-left: 0px !important;
		border-radius: 40px 0px 0px 0px;
	}

	.lcar-header-middle {
		max-width: 150px;
	}

	.lcar-header-end {
		border-radius: 0px 40px 40px 0px;
		max-width: 30px;
	}

	.page-header {
		background: black !important;
		color: white;
		text-align: right;
		max-width: 200px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-family: var(--lcars-header-font);
		font-size: x-large;
        text-transform: uppercase;
        line-height: 1;
	}

.lcar-content {
    max-height: calc(100% - 60px - 30px - 45px);
	flex: 1;

	display: flex;
	flex-direction: row;
}

	.lcar-content > div {
		flex: 1;
	}

	.lcar-content-start {
		max-width: 150px;

		display: flex;
		flex-direction: column;
        box-sizing: content-box;
	}

		.lcar-content-start > div {
			flex: 1;
			margin-top: 10px;
			background: #F89B24;
		}

		.lcar-content-start-top {
			margin-top: 0px !important;
		}

		.lcar-emphasis {
			background: #CC6666 !important;
			max-height: 150px;

            animation: fadein .5s ease;
            animation-delay: .4s !important;
		}

	.lcar-content-round {
		max-width: 24px;
		border-radius: 20px 0px 0px 20px;
		background: black;
		transform: translateX(-20px);
	}

.lcar-footer {
	flex: 1;
	max-height: 30px;
    margin-bottom: 0.75rem;
    z-index: 100;

	display: flex;
	flex-direction: row;
}

	.lcar-footer > div {
		margin-left: 10px;
		background: #F89B24;
		flex: 1;
	}

	.lcar-footer-start {
		margin-left: 0px !important;
		border-radius: 0px 0px 0px 40px;
	}

	.lcar-footer-end {
		max-width: 250px;
		background: $lcars-brighter-purple !important;

        animation: fadein .5s ease;
        animation-delay: .4s !important;
	}

    .lcar-footer .lcar-text {
        color: black;
        font-family: var(--lcars-header-font);
        font-size: 15px;
        padding: 3px 6px 3px 6px;
        text-transform: uppercase;
        box-sizing: content-box;
    }

.lcar-content-action {
    max-height: 34px;
    margin-bottom: 4px;
}

.lcar-content-history,
.lcar-content-profile,
.lcar-content-feedback,
.lcar-content-news {
    color: black;
    text-align: right;
    font-family: var(--lcars-header-font);
    font-size: x-large;
    max-width: 120px;
    padding: 3px 11px 6px 0px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: content-box;
    line-height: normal;
}

.lcar-content-left-button {
    color: black;
    text-align: right;
    font-family: var(--lcars-header-font);
    font-size: x-large;
    max-width: 120px;
    padding: 3px 11px 6px 0px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: content-box;
    line-height: normal;
}

    .lcar-content-history {
        padding: 3px 11px 8px 0px;
        background-color: #9999FF !important;
        animation: fadein .5s ease;
        animation-delay: .4s !important;
    }

    .lcar-content-credits {
        background-color: $lcars-dull-orange !important;
        max-height: 34px;
    }

    .lcar-content-profile {
        padding: 3px 11px 8px 0px;
        background-color: #DE84B7 !important;
    }

    .lcar-content-feedback {
        background-color: #F9AC76 !important;
    }

    .lcar-content-news {
        background-color: #CC6666 !important;
    }

.legal {
    text-align: center;
    color: #906F9A;
    font-size: small;
}

.logo {
    margin-top: 5px;
    margin-left: 157px;
}

.page-background {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

#page-content {
    max-width: 800px;
    margin: auto;
}

.page-text,
.page-text-aligned {
    color: white;
    font-size: large;
    margin-bottom: 10px;
    padding: 4px;
    font-weight: normal;
    text-align: justify;
}
.page-text {
    max-width: 700px;
}

.container .page-text {
    max-width: inherit;
}
    .page-text-aligned {
        margin-left: 0;
        margin-right: 0;
    }

.page-title {
    color: white;
    background-color: rgba(32, 32, 32, 0.5);
    padding: 10px;
    font-size: 26px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    font-family: roman_antiqueregular;
    text-shadow: 2px 2px 4px black;
}

.panel {
    color: white;
    max-width: 90%;
    border-radius: 0px;
    padding: 5px 5px 10px 5px;
}

.panel > div {
    padding: 5px;
}

.shadow-box {
    box-shadow: 0px 0px 10px #000;
}

table.selection-list {
    width: 100%;
    color: white;
    font-size: 12px;
    td {
        padding: 5px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.4);

        div {
            line-height: normal;
        }
    }
}

.text-selection {
    color: #DE84B7;
}

table.aspect-list td:first-child {
    width: 50%;
}

.source {
    background-color: #CC99CC;
    color: black;
    border: #CC99CC 3px solid;
    margin-right: 10px;
    padding: 10px;
    border-radius: 20px;
    text-align: right;
    font-family: var(--lcars-header-font);
    font-size: large;
    text-transform: uppercase;
    width: 144px;
    margin-top: 5px;
    margin-bottom: 10px;
    opacity: 0.8;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
    .source:hover {
        opacity: 1.0;
    }

    .source-selected {
        background-color: #CC6699;
        border-color: #CC6699;
        color: black;
    }

    .source-emphasis {
        background-color: #CC9966;
        border-color: #CC9966;
        color: black;
    }

.source-container {
    max-width: 50%;
}

.source.unavailable {
    background-color: #aaaaaa;
    border-color: #aaaaaa;
    cursor: not-allowed;

    :hover {
        background-color: #aaaaaa;
        border-color: #aaaaaa;
    }
}


.source-name,
.source-select {
    display: inline-block;
}
    .source-name {
        transform: translate(5px, -8px);
    }

.talent-filter {
    display: inline-block;
    padding-right: 10px;
}

#version {
    font-family: var(--lcars-header-font);
    font-size: large;
}

.table.table-dark tr td {
    background-color: black;
}

.table.table-dark.table-striped tr:nth-of-type(2n+1) > td {
    --bs-table-bg-type: var(--bs-gray-900);
}


.table td > p:last-child {
    margin-bottom: 0.25rem;
}

@media screen and (min-width: 0px) and (max-width: 768px) {

    #app {
        margin: 10px 0 5px -20px;
    }

    .desc-text {
        font-size: small;
    }

    .header-small {
        width: 100%;
    }

    .header-text {
        font-size: 18px;
    }

    h2.header-text {
        font-size: 14px;
    }

    input[type=text],
    input[type=number] {
        max-width: 70%;
    }

    .lcar-content-action {
        margin-top: 5px !important;
    }

    .lcar-content-history,
    .lcar-content-profile,
    .lcar-content-feedback,
    .lcar-content-news,
    .lcar-content-left-button {
        text-align: center;
        padding: 3px 18px 8px 0px;
        font-size: 1.6em;
    }

    .lcar-content {
        max-height: calc(100% - 50px - 20px - 45px);
    }

    .lcar-content-feedback {
        margin-top: 5px !important;
    }

    .lcar-content-start {
        max-width: 80px;
    }

    .lcar-header {
        max-height: 40px;
    }

    .lcar-footer {
        max-height: 20px;
    }

    .lcar-footer .lcar-text {
        font-size: 11px;
    }

    .lcar-header > div:nth-child(2),
    .lcar-header-middle,
    .lcar-header-start > b {
        display: none;
    }

    .legal {
        font-size: x-small;
        margin-right: 20px;
    }

    .logo {
        width: 120px;
        margin-left: 57px;
        margin-right: 2px;
    }

    .page-header {
        padding: 9px 4px;
        font-size: medium;
    }

    .page-text,
    .page-text-aligned {
        font-size: small;
    }

    select {
        max-width: 100%;
    }

    .selection-list td div.button-small, .selection-list td button.button-small {
        display: none;
    }

    .source-container {
        max-width: 100%;
    }

    table.selection-list td {
        padding: 10px;
        font-size: x-small;
    }

    .copyright {
        font-size: x-small;
    }

}

/* ANIMATIONS */

@keyframes container-grow {
    0% {
        transform: scale(.9, .9);
        opacity: 0;
    }
    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}

@keyframes content-grow {
    0% {
        max-height: 1%;
    }
    100% {
        max-height: 100%;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    33% {
        opacity: .6;
    }
    55% {
        opacity: .3;
    }
    100% {
        opacity: 1;
    }
}
