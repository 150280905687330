﻿#Agility,
#Awareness,
#Brawn,
#Coordination,
#Intelligence,
#Personality,
#Willpower {
    display: inline-block;
}

.attribute-container {
    font-family: var(--lcars-header-font);
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    width: 100px;
    height: 35px;
    background-color: #906F9A;
    color: white;
    padding: 2px 3px 3px 3px;
    margin: 2px;
    text-align: right;
    border: 2px solid #906F9A;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.attribute-value {
    display: inline-block;
    background-color: black;
    color: white;
    padding: 6px 5px;
    width: 100px;
    height: 35px;
    text-align: center;
    margin: 2px;
    border: 2px solid #906F9A;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
}
    .attribute-value > img {
        margin-right: 5px;
    }

.attribute-points {
    font-size: 14px;
    text-shadow: 1px 1px 1px #333;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .attribute-container {
        width: 60px;
    }

    .attribute-value {
        width: 80px;
    }
}