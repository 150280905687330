﻿.modal-dialog .sheet-selection-item {
    display: inline-block;
    padding: 16px;
    vertical-align: top;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 15px;
    box-sizing: border-box;
    width: 234px;
}

.modal-dialog .sheet-selection-item.selected {
    border: 1px solid white;
}

.modal-dialog .sheet-selection-item  .sheet-selection-item-name {
    width: 200px;
    min-height: 45px;
    text-align: center;
}

.modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail {
    position: relative;
    width: 200px;
    height: 260px;
}

.modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail img.thumbnail {
    width: 200px;
    height: auto;
}

.modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail img.overlay {
    position: absolute;
    left: 50%;
    top: 75px;
    transform: translateX(-50%);
}

.modal-content a, .modal-content a:hover {
  color: $lcars-light-orange;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {

  .modal-dialog.modal-lg {
      width: 90%;
      max-width: 90%;
  }

  .modal-dialog .sheet-selection-item {
    padding: 12px;
    width: 166px;
  }

  .modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail {
    width: 140px;
    height: 183px;
  }

  .modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail img.thumbnail {
    width: 140px;
  }

  .modal-dialog .sheet-selection-item  .sheet-selection-item-name {
    width: 140px;
  }
}

.modal-dialog .style-selections {
  clear: both;
  text-align: left;
}

.modal-header .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #B5B3D8;
  margin: -1rem -1rem -1rem auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1rem 1rem;
}
